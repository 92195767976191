<template>
  <div>
    <PageHeader fileName="header-10" withTablet withMobile>
      <h3>{{ $lang("title") }}</h3>
    </PageHeader>

    <section class="py-7">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 d-flex flex-column">
            <h2 class="mb-4" v-html="$lang('header.title')" />
          </div>
          <div class="col-lg-6">
            <h6 class="fw-400 mb-4 mt-3">{{ $lang("header.paragraph1") }}</h6>

            <p>
              {{ $lang("header.paragraph2") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-dark py-7">
      <div class="container">
        <div class="row mb-6">
          <div class="col-lg-5 d-flex flex-column">
            <h3 class="text-tertiary mb-3">{{ $lang("exams.title") }}</h3>

            <h6
              class="text-tertiary"
              v-for="(type, ti) of $lang('exams.types')"
              :key="ti"
            >
              <i class="fa fa-arrow-right mr-3" />
              {{ type }}
            </h6>
          </div>
          <div class="col-lg-6 offset-lg-1">
            <p>
              {{ $lang("exams.paragraph1") }}
            </p>

            <p>
              {{ $lang("exams.paragraph2") }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-5 d-flex flex-column">
            <h3 class="text-tertiary mb-3">
              {{ $lang("certificates.title") }}
            </h3>

            <h6
              class="text-tertiary"
              v-for="(type, ti) of $lang('exams.types')"
              :key="ti"
            >
              <i class="fa fa-arrow-right mr-3" />
              {{ type }}
            </h6>

            <div class="card card-outline-tertiary mt-auto d-flex mb-5 mb-lg-0">
              <div class="card-body d-flex gap-3">
                <h6 class="w-50 fw-500">
                  {{ $lang("certificates.information.card1.title") }}
                </h6>
                <ul class="disc-style">
                  <li>{{ $lang("certificates.information.card1.detail1") }}</li>
                  <li>{{ $lang("certificates.information.card1.detail2") }}</li>
                  <li>{{ $lang("certificates.information.card1.detail3") }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-6 offset-lg-1">
            <p>
              {{ $lang("certificates.paragraph1") }}
            </p>
            <p>
              {{ $lang("certificates.paragraph2") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="py-7 bg-tertiary">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <h4 class="mb-5 mb-lg-0 text-dark">
              {{ $lang("footer.title") }} <br />
              <span class="material-symbols-outlined arrow_right_alt">
                arrow_right_alt
              </span>
            </h4>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card1.title')"
              :to="{ name: 'methodology', hash: '#levels' }"
            >
              <small class="m-0">
                {{ $lang("footer.card1.body") }}
              </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card2.title')"
              :to="{ path: '/courses/our-courses' }"
            >
              <small class="m-0">
                {{ $lang("footer.card2.body") }}
              </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card3.title')"
              :to="{ name: 'student_visa' }"
            >
              <small class="m-0">
                {{ $lang("footer.card3.body") }}
              </small>
            </CardButton>
          </div>
        </div>
      </div>
    </section>

    <ContactFormSection fileName="footer-9" theme="tertiary">
      {{ $lang("contact_form.location") }}
    </ContactFormSection>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import ContactFormSection from "@/components/ContactFormSection.vue";
import CardButton from "@/components/CardButton.vue";

export default {
  components: {
    CardButton,
    PageHeader,
    ContactFormSection,
  },
};
</script>

<style></style>
